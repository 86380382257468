export const FareCalculator = (distance,time,rateDetails,instructionData, decimal, zones, polypoints,price)=>{  

    function checkIntersection(point, vs) {
        var x = point[0], y = point[1];
        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i][0], yi = vs[i][1];
            var xj = vs[j][0], yj = vs[j][1];
            
            var intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    }

    
    let total = 0;
    if(rateDetails.variable_distance &&  rateDetails.variable_distance.length > 0){
        const vr = rateDetails.variable_distance;
    if(vr.length > 0 && time !== 0 && distance !== 0){
        let distanceAmmount = 0;
        let hourAmmount = 0;
        let actTime = time/60;
        for(let i = 0; i< vr.length; i++){
            if(distance > vr[i].kmFrom && distance> vr[i].kmTo){  
                distanceAmmount = distanceAmmount+((vr[i].kmTo - vr[i].kmFrom) * vr[i].lekKm);
            }else if(distance > vr[i].kmFrom && distance<= vr[i].kmTo){
                distanceAmmount = distanceAmmount+((distance - vr[i].kmFrom) * vr[i].lekKm);
                hourAmmount = actTime * vr[i].lekMin;
            }else {
                break;
            }
        }
        total = distanceAmmount + hourAmmount;
    }
    }else{
        let baseCalculated = 0;
        const initial_units = parseFloat(rateDetails.initial_units)>0 ? parseFloat(rateDetails.initial_units) : 0;
        const intial_rate = parseFloat(rateDetails.intial_rate);
        const actual_distance = parseFloat(distance);
        const rate_per_unit_distance = parseFloat(rateDetails.rate_per_unit_distance);
        if(initial_units>0){
            baseCalculated = intial_rate;
        }

        baseCalculated =  baseCalculated + (actual_distance > initial_units? (rate_per_unit_distance * (actual_distance - initial_units)) : 0);

        baseCalculated =  baseCalculated + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));

        if(rateDetails.base_fare>0){
            baseCalculated = baseCalculated + rateDetails.base_fare;
        }
        if(instructionData && instructionData.parcelTypeSelected){
            baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
        }
        if(instructionData && instructionData.optionSelected){
            baseCalculated = baseCalculated + instructionData.optionSelected.amount;
        }

        total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);
    }


    if(rateDetails.peak_hours && rateDetails.peak_hours.length>0){
        for(let i=0; i<rateDetails.peak_hours.length;i++){
            let peakRow = rateDetails.peak_hours[i];
            const peak_start_time = (new Date(peakRow.start_time).getHours() * 60) + new Date(peakRow.start_time).getMinutes();
            const peak_end_time = (new Date(peakRow.end_time).getHours() * 60) + new Date(peakRow.end_time).getMinutes();
            const cur_time = (new Date().getHours() * 60) + new Date().getMinutes();
            if(cur_time >= peak_start_time && cur_time< peak_end_time){
                total = total + (total * parseFloat(peakRow.price_surge)/100);
            } 
        }  
    }

    if(zones && zones.length > 0){
        for(let i=0; i < zones.length; i++){
            if(zones[i].zone_path && zones[i].active){
                for(let j=0; j<polypoints.length;j++){
                    if(checkIntersection(polypoints[j], zones[i].zone_path)){
                        total = total + zones[i].zone_surge;
                        break;
                    }
                }
            }
        }
    }

    let triptotal = price > 0 ? price : total;
    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        convenienceFee = rateDetails.convenience_fees;
    }else{
        convenienceFee = (triptotal*parseFloat(rateDetails.convenience_fees)/100);
    }
    let grand = triptotal + convenienceFee;

    return {
        totalCost:parseFloat(total.toFixed(decimal)),
        grandTotal:parseFloat(grand.toFixed(decimal)),
        convenience_fees:parseFloat(convenienceFee.toFixed(decimal))
    }
     
}
