export const FirebaseConfig = {
	"projectId": "pick-me-up-29d71",
	"appId": "1:259698511927:web:c46c86633a389befa52fb2",
	"databaseURL": "https://pick-me-up-29d71-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "pick-me-up-29d71.appspot.com",
	"locationId": "europe-central2",
	"apiKey": "AIzaSyCunpPyit3ePgg8Kia3h7coh3mQNp8rVAo",
	"authDomain": "pick-me-up-29d71.firebaseapp.com",
	"messagingSenderId": "259698511927",
	"measurementId": "G-BQWEJPB0GL"
};