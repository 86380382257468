import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    TextField,
} from '@mui/material';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { makeStyles } from '@mui/styles';
import { api } from 'common';
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { downloadCsv } from '../common/sharedFunctions';
import moment from 'moment/min/moment-with-locales';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    container: {
        marginTop: theme.spacing(1),
        backgroundColor: colors.SECONDARY,
        alignContent: 'center',
        borderRadius: "8px",
        width: '100%',
    },
    inputContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: colors.WHITE,
        borderRadius: "8px",
        width: '50%',
        padding: '30px',
        top: "19px",
        boxShadow: "4px 4px 6px #9E9E9E"
    },
    container1: {
        backgroundColor: colors.WHITE,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        padding: '30px',
        width: '100%',
        top: "19px",
        boxShadow: "4px 4px 6px #9E9E9E"
    },
    title: {
        color: colors.WHITE,
        padding: '10px'
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: colors.THIRDCOLOR
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    carphotoRtl: {
        height: '16px',
        marginLeft: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: 50,
        borderRadius: "30px",
        backgroundColor: colors.MAIN_COLOR,
        color: '#FFF',
        marginTop: 10
    },
    inputRtl: {
        "& label": {
            right: 25,
            left: "auto"
        },
        "& legend": {
            textAlign: "right",
            marginRight: 18
        }
    },
    rightRty: {
        "& legend": {
            marginRight: 30
        }
    }
}));


function OutStationAddress() {

    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const {
        fetchOutStationAddress,
        editOutStation
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const settings = useSelector(state => state.settingsdata.settings);
    const outstation = useSelector(state => state.outstationadddata.outstation);
    const [locationPick, setPickLocation] = useState(null);
    const [locationDrop, setDropLocation] = useState(null);
    const rootRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
    const [price1, setPrice1] = useState();
    const [price2, setPrice2] = useState();
    const [price3, setPrice3] = useState();
    const [tableData, setTableData] = useState([])

    const columns = [
        { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('lll') : null, cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        { title: t('location_pick'), field: 'pickup.desc', editable: 'never', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        { title: t('location_drop'), field: 'drop.desc', editable: 'never', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        { title: t('pickup_city'), field: 'pickupcity', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        { title: t('drop_city'), field: 'dropcity', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        // { title: t('tier1'), field: 'tier1', type: 'numeric', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        { title: t('tier2'), field: 'tier2', type: 'numeric', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } },
        { title: t('tier3'), field: 'tier3', type: 'numeric', cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' } }
    ];


    useEffect(() => {
        if (outstation) {
            setTableData(outstation)
        }
    }, [outstation]);

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({ open: false, msg: '' })
    };

    const [state, setState] = useState({
        pickupcity: null,
        dropcity: null
      });

    const handleCity = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    // const handlePrice1 = (e) => {
    //     setPrice1(Number(e.target.value))
    // }

    const handlePrice2 = (e) => {
        setPrice2(Number(e.target.value))
    }

    const handlePrice3 = (e) => {
        setPrice3(Number(e.target.value))
    }


    const addOutStation = () => {
        new Promise((resolve, reject) => {
            setLoader(true);
            setTimeout(() => {
                    if (locationPick && locationDrop) {
                        if(state.pickupcity && state.dropcity){
                        if ( price2 && price3) {
                            let obj = {
                                pickup: { lat: locationPick.coords.lat, lng: locationPick.coords.lng , desc: locationPick.description },
                                drop: { lat: locationDrop.coords.lat, lng: locationDrop.coords.lng, desc: locationDrop.description },
                                pickupcity: state.pickupcity,
                                dropcity: state.dropcity,
                                createdAt: new Date().getTime(),
                                //tier1: price1,
                                tier2: price2,
                                tier3: price3
                            }
                            dispatch(editOutStation(obj, "Add"));
                            dispatch(fetchOutStationAddress());
                            setPickLocation(null);
                            setDropLocation(null);
                            setState({
                                pickupcity: null,
                                dropcity: null
                            });
                            //setPrice1(null);
                            setPrice2(null);
                            setPrice3(null);
                            setLoader(false);
                            resolve();
                        } else {
                            reject();
                            setLoader(false);
                            setCommonAlert({ open: true, msg: t('proper_price') });
                        }
                    }else{
                        reject();
                        setLoader(false);
                        setCommonAlert({ open: true, msg: t('select_city') });
                    }
                    } else {
                        reject();
                        setLoader(false);
                        setCommonAlert({ open: true, msg: t('select_location') });
                    }
                
            }, 600)
        }).catch((e) => { })
    }

    return (
        <>
            {loader ? <CircularLoading /> :
                <div className={classes.container} ref={rootRef}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                                {t('outstation_address')}
                            </Typography>
                        </Grid>
                        <div className={classes.container1}>
                            <div className={classes.inputContainer}>
                                <Grid container spacing={2}  >

                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <Grid item xs={12} >
                                            <GoogleMapsAutoComplete
                                                variant={"outlined"}
                                                placeholder={t('location_pick')}
                                                value={locationPick}
                                                className={classes.items}
                                                onChange={
                                                    (value) => {
                                                        setPickLocation(value)
                                                    }
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{marginTop: 5}}>
                                            <TextField
                                                margin="dense"
                                                id="pickupcity"
                                                name='pickupcity'
                                                label={t('pickup_city')}
                                                type="email"
                                                fullWidth
                                                variant="outlined"
                                                onChange={handleCity}
                                                value={state.pickupcity}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <TextField
                                                margin="dense"
                                                id="tier1"
                                                name='tier1'
                                                label={t('tier1')}
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                onChange={handlePrice1}
                                                value={price1}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="dense"
                                                id="tier2"
                                                name='tier2'
                                                label={t('tier2')}
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                onChange={handlePrice2}
                                                value={price2}
                                            />
                                        </Grid>
                                        
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <Grid item xs={12} >
                                            <GoogleMapsAutoComplete
                                                placeholder={t('location_drop')}
                                                variant={"outlined"}
                                                value={locationDrop}
                                                className={classes.items}
                                                onChange={
                                                    (value) => {
                                                        setDropLocation(value);
                                                    }
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{marginTop: 5}}>
                                            <TextField
                                                margin="dense"
                                                id="dropcity"
                                                name='dropcity'
                                                label={t('drop_city')}
                                                type="email"
                                                fullWidth
                                                variant="outlined"
                                                onChange={handleCity}
                                                value={state.dropcity}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="dense"
                                                id="tier3"
                                                name='tier3'
                                                label={t('tier3')}
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                onChange={handlePrice3}
                                                value={price3}
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button
                                            size="lg"
                                            onClick={addOutStation}
                                            variant="contained"
                                            color="success"
                                            className={classes.buttonStyle}
                                        >
                                            <i className="fa fa-book fa-fw" style={isRTL === 'rtl' ? { marginLeft: 5 } : { marginRight: 5 }} />
                                            {t('add')}
                                        </Button>
                                    </Grid>

                                </Grid>
                            </div>
                            <Grid container>
                                <Grid item xs={12} >
                                    <MaterialTable
                                        title={t('outstation_web')}
                                        columns={columns}
                                        style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E" }}
                                        data={tableData}
                                        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                        options={{
                                            exportCsv: (columns, data) => {
                                                let hArray = [];
                                                const headerRow = columns.map(col => {
                                                    if (typeof col.title === 'object') {
                                                        return col.title.props.text;
                                                    }
                                                    hArray.push(col.field);
                                                    return col.title;
                                                });
                                                const dataRows = data.map(({ tableData, ...row }) => {
                                                    row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' ' + new Date(row.createdAt).toLocaleTimeString()
                                                    let dArr = [];
                                                    for (let i = 0; i < hArray.length; i++) {
                                                        dArr.push(row[hArray[i]]);
                                                    }
                                                    return Object.values(dArr);
                                                })
                                                const { exportDelimiter } = ",";
                                                const delimiter = exportDelimiter ? exportDelimiter : ",";
                                                const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                                                const csvFileName = 'download.csv';
                                                downloadCsv(csvContent, csvFileName);
                                            },
                                            exportButton: {
                                                csv: settings.AllowCriticalEditsAdmin,
                                                pdf: false,
                                            },
                                            maxColumnSort: "all_columns",
                                            rowStyle: rowData => ({
                                                backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE
                                            }),
                                            editable: {
                                                backgroundColor: colors.WHITE,
                                                fontSize: "0.8em",
                                                fontWeight: 'bold ',
                                                fontFamily: 'Lucida Console", "Courier New", monospace'
                                            },
                                            headerStyle: {
                                                position: "sticky",
                                                top: "0px",
                                                backgroundColor: colors.SECONDARY,
                                                color: colors.WHITE,
                                                fontSize: "0.8em",
                                                fontWeight: 'bold ',
                                                fontFamily: 'Lucida Console", "Courier New", monospace',
                                                textAlign: isRTL === 'rtl' ? 'center' : 'left'
                                            }
                                        }}
                                        localization={{
                                            body: {
                                                addTooltip: (t('add')),
                                                deleteTooltip: (t('delete')),
                                                editTooltip: (t('edit')),
                                                emptyDataSourceMessage: (
                                                    (t('blank_message'))
                                                ),
                                                editRow: {
                                                    deleteText: (t('delete_message')),
                                                    cancelTooltip: (t('cancel')),
                                                    saveTooltip: (t('save'))
                                                },
                                            },
                                            toolbar: {
                                                searchPlaceholder: (t('search')),
                                                exportTitle: (t('export')),
                                            },
                                            header: {
                                                actions: (t('actions'))
                                            },
                                            pagination: {
                                                labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
                                                firstTooltip: (t('first_page_tooltip')),
                                                previousTooltip: (t('previous_page_tooltip')),
                                                nextTooltip: (t('next_page_tooltip')),
                                                lastTooltip: (t('last_page_tooltip'))
                                            },
                                        }}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    setLoader(true);
                                                    setTimeout(() => {
                                                        if (!(newData && newData.tier2 && newData.tier3)) {
                                                            setCommonAlert({ open: true, msg: t('proper_price') });
                                                            reject();
                                                        } else {
                                                            resolve();
                                                            if (newData !== oldData) {
                                                                delete newData.tableData;
                                                                dispatch(editOutStation(newData, "Update"));
                                                                dispatch(fetchOutStationAddress());
                                                                setLoader(false);
                                                            }
                                                        }
                                                    }, 600);
                                                }),
                                            onRowDelete: oldData =>
                                                settings.AllowCriticalEditsAdmin ?
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            dispatch(editOutStation(oldData, "Delete"));
                                                        }, 600);
                                                    })
                                                    :
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            setCommonAlert({ open: true, msg: t('demo_mode') });
                                                        }, 600);
                                                    })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
                </div>
            }
        </>
    )
}

export default OutStationAddress